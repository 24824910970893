@import url('https://fonts.googleapis.com/css?family=Lato');


.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.topping {
  margin-top: 0.3rem;
  vertical-align: text-bottom;
}

.result {
  margin-top: 1rem;
}

.btn-circle.btn-md {
    width: 100px;
    height: 100px;
    padding: 7px 10px;
    border-radius: 50px;
    font-size: 20px;
    text-align: center;
}

.btn-circle.btn-sm {
    width: 80px;
    height: 80px;
    padding: 6px 0px;
    border-radius: 40px;
    font-size: 16px;
    text-align: center;
}

.btn-circle.btn-vsm {
    width: 40px;
    height: 40px;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 17px;
    text-align: center;
}

.bg-image{
    height: complex;
}

.bg-color-custom{
    background-color: #eff7f8;

}

.gradient-custom {
    height: 100vh;
background: rgb(177,177,177);
background: linear-gradient(90deg, rgba(177,177,177,1) 0%, rgba(204,204,204,0.8743872549019608) 50%, rgba(175,175,175,1) 100%);
}


.navbar-hidden{
    background-color: #f5f5f5;
}


.text-custom{
    font-family: "Lato", sans-serif;
    color: #425352;
}

.text-title{
    font-size: xx-large;
}

.card{
    /*background-color: #eeeeee;*/
    background-color: #ffffff;
    border-radius: 0px;
}

.btn{
    border-radius: 5px;
    white-space:normal !important;
    word-wrap:break-word;
}

.btn-val{
    background-color: #B7E9F7;
    border-color: #c1c1c1;
}

.btn-change{
    background-color: #f3e0b0;
    border-color: #c1c1c1;
}

.btn-grey{
    background-color: #e2e2e2;
    border-color: #c1c1c1
}
.btn-light{
    background-color: white;
}

.btn-delete{
    background-color: #ee8e68;
    border-color: #c1c1c1
}



/*.btn-outline-secondary{*/
/*    background-color: #e2e2e2;*/
/*}*/

.btn-round{
    border-radius: 20px;
}

.btn-active:focus{
    color: black;
}

.btn-warning:focus{
    color: white;
}


.card-grey{
    background-color: #e2e2e2;
}

.card-inv{
    background-color: transparent;
}

.alert{
    border-radius: 0px;
}

.input-group-text{
    border-radius: 0px;
}

.sigContainer {
    /*width: 100%;*/
    /*height: 100%;*/
    /*background-color: #e7e7e7;*/
}

.sigPad {
    width: 100%;
    height: 100%;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}

.container-custom{
background-color: #f5f5f5;
}


.lbl-checkbox {
  display: inline-block;
  padding: 5em;
  background: #eee;
}

.iq-top-navbar{
    left : 0;
    width: auto;
}

.iq-top-navbar.fixed-header{
    left : 0;
    width: auto;
}

.content-page{
    margin-left : 0;
}

@media (max-width: 479px) {
    .content-page {
        padding: 120px 0 0;
    }
}

.navbar-brand{
    font-size: xx-large;
}

.form-control-custom{
    border-bottom-right-radius: 0;
}

.dropdown {
    position: relative;
}

.hk--custom--select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){
    width: auto !important;
    min-width: 230px;
}

.dropdown-menu {
    top: 100%;
    width: max-content;
    right: 0;
}

.dropdown-item.active{
    background-color: lightgray;
    color : black;
}

.dropdown-item.active:hover {
    background-color: lightgray !important;
}

.dropdown-item.active:focus {
    background-color: lightgray !important;
    color: black;
}

.list-group-item-custom {
    border-radius: 0;
}

.list-group-item-rounded {
    border-radius: 0;
}

.list-group-item-custom:first-child {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important;
}

.list-group-item-custom:last-child {
    border-bottom-right-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
}

.list-group-item-rounded:first-child {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}

.list-group-item-rounded:last-child {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.no-radius {
    border-radius: 0 !important;
}

.border-dark {
    border-width: 2px !important;
    border-color: black;
}

.border-dark-big {
    border-width: 4px !important;
    border-color: black;
}



.border-danger {
    border-width: 4px !important;
}

.textarea {
  line-height: 25px;
}

.rounded-0-bottom {
    border-bottom-left-radius : 0 !important;
    border-bottom-right-radius : 0 !important;
}

.rounded-0-top {
    border-top-left-radius : 0 !important;
    border-top-right-radius : 0 !important;
}

.rounded-sm {
    border-bottom-left-radius: 5px !important;
}

.border-grey{
    border-color: black;
}

.dropdown-menu-custom{
    max-height: 250px;
    overflow-y: auto;
}

/*.btn-long{*/
/*    min-width: 150px;*/
/*}*/

.text-custom{
    text-align: center !important;
}

.custom-scanner{
    width: auto;
}

.custom-container{
    width: auto;
}

.navbar-toggler{
    right: 20px !important;
    top: 18px !important;
}

.navbar-collapse{
    position: static;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.modal{
    display: block
}

.custom-logo{
    max-width: 250px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background: linear-gradient(to right, rgba(13, 181, 200, 1) 0%, rgba(8, 155, 171, 1) 100%) !important;;
    border-color: #007bff;
}

.btn-outline-primary:hover{
    background: linear-gradient(to right, rgba(13, 181, 200, 1) 0%, rgba(8, 155, 171, 1) 100%) !important;;
}

.custom-shadow-bottom{
    box-shadow: 0 8px 12px -12px gray;
}

.border-success{
    border-color: #28a745 !important;
}

.initial-avatar {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /*!* Colors *!*/
    /*background-color: #d1d5db;*/
    /*color: #fff;*/
    font-size: large;

    /* Rounded border */
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
}

.custom-icon:before {
    position: relative;
    padding-top: 3rem;
}

.nav-link-color {
    color: rgba(0, 0, 0, .5);
}