@import url(https://fonts.googleapis.com/css?family=Lato);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.topping {
  margin-top: 0.3rem;
  vertical-align: text-bottom;
}

.result {
  margin-top: 1rem;
}

.btn-circle.btn-md {
    width: 100px;
    height: 100px;
    padding: 7px 10px;
    border-radius: 50px;
    font-size: 20px;
    text-align: center;
}

.btn-circle.btn-sm {
    width: 80px;
    height: 80px;
    padding: 6px 0px;
    border-radius: 40px;
    font-size: 16px;
    text-align: center;
}

.btn-circle.btn-vsm {
    width: 40px;
    height: 40px;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 17px;
    text-align: center;
}

.bg-image{
    height: complex;
}

.bg-color-custom{
    background-color: #eff7f8;

}

.gradient-custom {
    height: 100vh;
background: rgb(177,177,177);
background: linear-gradient(90deg, rgba(177,177,177,1) 0%, rgba(204,204,204,0.8743872549019608) 50%, rgba(175,175,175,1) 100%);
}


.navbar-hidden{
    background-color: #f5f5f5;
}


.text-custom{
    font-family: "Lato", sans-serif;
    color: #425352;
}

.text-title{
    font-size: xx-large;
}

.card{
    /*background-color: #eeeeee;*/
    background-color: #ffffff;
    border-radius: 0px;
}

.btn{
    border-radius: 5px;
    white-space:normal !important;
    word-wrap:break-word;
}

.btn-val{
    background-color: #B7E9F7;
    border-color: #c1c1c1;
}

.btn-change{
    background-color: #f3e0b0;
    border-color: #c1c1c1;
}

.btn-grey{
    background-color: #e2e2e2;
    border-color: #c1c1c1
}
.btn-light{
    background-color: white;
}

.btn-delete{
    background-color: #ee8e68;
    border-color: #c1c1c1
}



/*.btn-outline-secondary{*/
/*    background-color: #e2e2e2;*/
/*}*/

.btn-round{
    border-radius: 20px;
}

.btn-active:focus{
    color: black;
}

.btn-warning:focus{
    color: white;
}


.card-grey{
    background-color: #e2e2e2;
}

.card-inv{
    background-color: transparent;
}

.alert{
    border-radius: 0px;
}

.input-group-text{
    border-radius: 0px;
}

.sigContainer {
    /*width: 100%;*/
    /*height: 100%;*/
    /*background-color: #e7e7e7;*/
}

.sigPad {
    width: 100%;
    height: 100%;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}

.container-custom{
background-color: #f5f5f5;
}


.lbl-checkbox {
  display: inline-block;
  padding: 5em;
  background: #eee;
}

.iq-top-navbar{
    left : 0;
    width: auto;
}

.iq-top-navbar.fixed-header{
    left : 0;
    width: auto;
}

.content-page{
    margin-left : 0;
}

@media (max-width: 479px) {
    .content-page {
        padding: 120px 0 0;
    }
}

.navbar-brand{
    font-size: xx-large;
}

.form-control-custom{
    border-bottom-right-radius: 0;
}

.dropdown {
    position: relative;
}

.hk--custom--select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){
    width: auto !important;
    min-width: 230px;
}

.dropdown-menu {
    top: 100%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    right: 0;
}

.dropdown-item.active{
    background-color: lightgray;
    color : black;
}

.dropdown-item.active:hover {
    background-color: lightgray !important;
}

.dropdown-item.active:focus {
    background-color: lightgray !important;
    color: black;
}

.list-group-item-custom {
    border-radius: 0;
}

.list-group-item-rounded {
    border-radius: 0;
}

.list-group-item-custom:first-child {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important;
}

.list-group-item-custom:last-child {
    border-bottom-right-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
}

.list-group-item-rounded:first-child {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}

.list-group-item-rounded:last-child {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.no-radius {
    border-radius: 0 !important;
}

.border-dark {
    border-width: 2px !important;
    border-color: black;
}

.border-dark-big {
    border-width: 4px !important;
    border-color: black;
}



.border-danger {
    border-width: 4px !important;
}

.textarea {
  line-height: 25px;
}

.rounded-0-bottom {
    border-bottom-left-radius : 0 !important;
    border-bottom-right-radius : 0 !important;
}

.rounded-0-top {
    border-top-left-radius : 0 !important;
    border-top-right-radius : 0 !important;
}

.rounded-sm {
    border-bottom-left-radius: 5px !important;
}

.border-grey{
    border-color: black;
}

.dropdown-menu-custom{
    max-height: 250px;
    overflow-y: auto;
}

/*.btn-long{*/
/*    min-width: 150px;*/
/*}*/

.text-custom{
    text-align: center !important;
}

.custom-scanner{
    width: auto;
}

.custom-container{
    width: auto;
}

.navbar-toggler{
    right: 20px !important;
    top: 18px !important;
}

.navbar-collapse{
    position: static;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.modal{
    display: block
}

.custom-logo{
    max-width: 250px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background: linear-gradient(to right, rgba(13, 181, 200, 1) 0%, rgba(8, 155, 171, 1) 100%) !important;;
    border-color: #007bff;
}

.btn-outline-primary:hover{
    background: linear-gradient(to right, rgba(13, 181, 200, 1) 0%, rgba(8, 155, 171, 1) 100%) !important;;
}

.custom-shadow-bottom{
    box-shadow: 0 8px 12px -12px gray;
}

.border-success{
    border-color: #28a745 !important;
}

.initial-avatar {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /*!* Colors *!*/
    /*background-color: #d1d5db;*/
    /*color: #fff;*/
    font-size: large;

    /* Rounded border */
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
}

.custom-icon:before {
    position: relative;
    padding-top: 3rem;
}

.nav-link-color {
    color: rgba(0, 0, 0, .5);
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.equation.cell {
  position: relative;
}
.error.cell {
  background: rgba(255,0,0,0.14);
  font-size: 0.8em;
  color: red;
}
.error.cell > div.text {
  text-align: center;
}
.equation.cell:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  border-style: solid;
  border-width: 6px 6px 0 0;
  border-color: #2185d0 transparent transparent transparent;
  z-index: 2;
}

.row-handle.cell {
  width: 1rem;
}

tbody .row-handle.cell, thead .cell:not(.row-handle) {
  cursor: move;
}

.data-grid-container table.data-grid tr {
  background: white;
}
.data-grid-container table.data-grid .drop-target,  .data-grid-container table.data-grid thead .cell.read-only.drop-target {
  background: #6F86FC;
  transition: none;
  color: white;
}
.data-grid-container table.data-grid thead .cell.read-only {
  transition: none;
}


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.container {
  /*max-width: 600px;*/
  margin: auto;
}
table.data-grid {
  margin: auto;
  width: 100%;
}
.Select-control, .Select-input, .Select-placeholder,.Select-clear, .Select-placeholder, .Select--single > .Select-control .Select-value {
  height: 15px;
  line-height: 12px;
  font-size: 12px;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.Select-control input{
  height: 16px;
  font-size: 12px;
  padding: 0;
}
.sheet-container {
  display: block;
  padding: 5px;

  box-shadow: 0px 0px 6px #CCC;
  margin: auto;
  width: 358px;
  margin-top: 20px;
  transition: box-shadow 0.5s ease-in;
}
.sheet-container:hover {
  transition: box-shadow 0.5s ease-in;

  box-shadow: 0px 0px 1px #CCC;
}
.sheet-container table.data-grid tr td.cell:not(.selected){
  border: 1px solid #ececec;
}
.sheet-container table.data-grid tr td.cell, .sheet-container table.data-grid tr th.cell {
  font-size: 12px;
}
div.divider {
  margin: 40px 0px;
  height: 1px;
  width: 100%;
  background-color: #EEE;
}
pre {
  display: inline-block;
  background: #333;
  padding: 10px 30px;
  border-left: 2px solid white;
}
.header {
  text-align: center;
  padding: 50px 0px;
  background: #e63946;
  color: #EEE;
  margin-bottom: 50px;
}
.footer-container a {
  color: white;
}
.footer-container {
  margin-top: 50px;
  text-align: center;
  padding: 50px 0px;
  background: #e63946;
  color: #EEE;
}
.add-grocery {
  text-align: left;
  padding: 5px 10px;
  color: #888;
}
.add-button {
  float: right;
  border-radius: 0;
  background: #CCC;
  border-radius: 2px;
  padding: 2px 20px;
  background: #e63946;
  color: white;
  cursor: pointer;
  font-size: 9px;
}
.add-button:hover {
  background: #f17d86;
}
.github-link {
  display: block;
  width: 200px;
  font-size: 12px;
  text-decoration: none;
  margin: auto;
  color: white;
}
.github-link:hover {
  color: #DDD;
}



span.data-grid-container, span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid {
  table-layout: fixed;
  border-collapse: collapse;
} 

.data-grid-container .data-grid .cell.updated {
    background-color: rgba(0, 145, 253, 0.16);
    transition : background-color 0ms ease ;
}
.data-grid-container .data-grid .cell {
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition : background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #DDD;
  padding: 1px;
}
.data-grid-container .data-grid .cell.selected:not(.button) {
  border: 1px double rgb(33, 133, 208);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

/*.btn {*/
/*  border: 0;*/
/*  transition: none;*/
/*  box-shadow: none;*/
/*}*/

.data-grid-container .data-grid .cell.read-only {
  background: white;
  color: #999;
  text-align: center;
}

.data-grid-container .data-grid .cell.read-only.grey {
  background: whitesmoke;
  color: #999;
  text-align: center;
}

.data-grid-container .data-grid .cell.read-only.red {
  color: red;
}

.data-grid-container .data-grid .cell.read-only.green {
  color: green;
}

.data-grid-container .data-grid .cell > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}


.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align:right;
  width: calc(100% - 0px);
  height: 22px;
  background: none;
  display: block;
}


.data-grid-container .data-grid .cell {
  vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
}

.button {
  align-content: center;
}

